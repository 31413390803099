var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overlay-bg px-4 pt-4 md:pt-28",on:{"click":function($event){_vm.$root.overlay = null}}},[_c('div',{staticClass:"w-128 m-auto rounded-xl max-h-full max-w-full flex flex-col",on:{"click":function($event){$event.stopPropagation();}}},[_c('form',{staticClass:"flex items-center px-4 bg-white shadow-xl rounded-xl flex-shrink-0 space-x-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('search-icon',{staticClass:"h-6 text-blue-400"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.q),expression:"q"},{name:"focus",rawName:"v-focus"}],ref:"search",staticClass:"flex-auto bg-transparent py-4 outline-none",attrs:{"placeholder":"Sök...","autocomplete":"off","autocapitalize":"off"},domProps:{"value":(_vm.q)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.stopPropagation();return _vm.scroll(true)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.stopPropagation();return _vm.scroll(false)}],"input":function($event){if($event.target.composing){ return; }_vm.q=$event.target.value}}}),_c('close-button')],1),_c('div',{staticClass:"px-4 overflow-y-auto"},[(_vm.search.user)?_c('internal-link',{staticClass:"block p-3 rounded-lg border-4 border-blue-400 bg-white my-2",attrs:{"to":{
          name: 'User',
          params: {
            username: _vm.search.user.username,
          },
        }}},[_c('user-circle-icon',{staticClass:"inline-block pr-2 align-middle text-gray-600 h-6"}),_vm._v(_vm._s(_vm.search.user.username)+" ")],1):_vm._e(),_vm._l((_vm.search.chips),function(chip,index){return _c('internal-link',{key:chip.id,ref:'search-chip-' + index,refInFor:true,attrs:{"to":{
          name: 'Chip',
          params: { chipSlug: chip.slug, brandSlug: chip.brand.id },
        }}},[_c('div',{staticClass:"flex items-center my-2 font-semibold tracking-wide p-3 rounded-lg bg-gray-100 border-4",class:{ active: index === _vm.active },on:{"mouseenter":function($event){_vm.active = index}}},[_c('picture',{staticClass:"h-10 min-w-6 mr-3 align-middle"},[_c('source',{attrs:{"srcset":("https://static.snackstoppen.se/snackstoppen/sm/snacks/" + (chip.image) + ".webp"),"type":"image/webp"}}),_c('img',{staticClass:"h-full mx-auto",attrs:{"src":("https://static.snackstoppen.se/snackstoppen/png/snacks/" + (chip.image) + ".png"),"onerror":"this.onerror=null; this.parentElement.querySelectorAll('source').forEach(el=>el.remove()); this.src='/img/chips/placeholder.svg'"}})]),_c('div',{staticClass:"inline-block tracking-wider align-middle"},[_vm._v(" "+_vm._s(chip.name)+" "),_c('p',{staticClass:"text-xs tracking-wider text-gray-400"},[_vm._v(" "+_vm._s(chip.brand.name)+" ")])])])])})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }