<template>
  <div class="overlay-bg flex items-center" @click="$root.overlay = null">
    <div class="relative w-96 max-w-full bg-white mx-auto p-6 rounded-xl" @click.stop>
      <close-button class="absolute right-0 top-0 mt-8 mr-8" />
      <h2 class="text-3xl font-semibold">Följare</h2>
      <div v-if="users" class="pt-4 gap-2">
        <div
          v-for="user in users"
          :key="user.id"
          class="flex flex-row p-1 rounded-lg hover:bg-gray-100 active:bg-gray-200 transition"
        >
          <internal-link
            :to="{ name: 'User', params: { username: user.username } }"
            class="flex w-full items-center space-x-1"
          >
            <user-circle-icon class="h-8 inline-block" />
            <span class="flex-grow align-middle">{{ user.username }}</span>
          </internal-link>
          <button
            v-if="!$root.user || user.id != $root.user.id"
            class="bg-blue-500 w-10 h-full text-white ml-2 p-2 rounded transition"
            :class="{ follow: user.follow }"
            @click="follow(user)"
          >
            <!-- {{ user.follow ? "Följer" : "Följ" }} -->
            <user-add-icon v-if="!user.follow" />
            <user-remove-icon v-else />
          </button>
        </div>
        <p v-if="users.length == 0">Inga följare.</p>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from "@/components/CloseButton.vue"
import gql from "graphql-tag";
export default {
  components: { CloseButton },
  props: ["username"],
  data: () => ({
    users: null,
  }),
  methods: {
    async follow(user) {
      if (user.follow) {
        try {
          await this.$root.unfollow(user);
        } catch (err) {
          this.$root.alert("Kunde inte avfölja");
          console.error(err);
        }
      } else {
        try {
          await this.$root.follow(user);
        } catch (err) {
          this.$root.alert("Kunde inte följa");
          console.error(err);
        }
      }
    },
  },
  apollo: {
    users: {
      query: gql`
        query FollowersUsersMessage($followers: String!) {
          users(followers: $followers) {
            id
            username
            firstname
            lastname
            image
            follow
          }
        }
      `,
      variables() {
        return {
          followers: this.username,
        };
      },
      fetchPolicy: "cache-and-network",
    },
  },
};
</script>
<style scoped lang="postcss">
.follow {
  @apply bg-gray-200
    text-gray-400
    hover:bg-red-300
    hover:text-red-600;
}
</style>
