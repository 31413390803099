<template>
  <transition name="overlay" appear>
    <div
      class="overlay-bg flex items-center justify-center"
      v-if="confirm"
      @click="
        confirm.resolve(false);
        confirms.shift();
      "
    >
      <div
        class="w-80 flex flex-col items-center space-y-8 bg-white rounded-xl p-8 mx-2 shadow-2xl"
        @click.stop
      >
        <div class="space-y-4">
          <p class="text-6xl text-center">🤔</p>
          <p class="text-xl text-center">{{ confirm.message }}</p>
        </div>
        <div>
          <button
            class="transition w-24 p-2 border-2 border-blue-500 text-blue-500 tracking-wider font-semibold rounded-lg hover:border-blue-700 hover:text-blue-700"
            @click="
              confirm.resolve(true);
              confirms.shift();
            "
          >
            Ok
          </button>
          <button
            class="transition w-24 ml-4 p-2 border-2 border-gray-300 hover:border-gray-400 text-gray-500 hover:text-gray-600 tracking-wider font-semibold rounded-lg"
            @click="
              confirm.resolve(false);
              confirms.shift();
            "
          >
            Avbryt
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: ["confirms"],
  computed: {
    confirm() {
      return this.confirms ? this.confirms[0] : null;
    },
  },
};
</script>
