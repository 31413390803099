<template>
  <internal-link :to="{ name: 'Brand', params: { brandSlug: brand.id } }">
    <div
      class="responsive-card w-80 h-32 sm:w-40 sm:h-60 flex items-center space-x-10 sm:space-x-0 sm:justify-center sm:flex-col m-3 bg-white p-4 rounded-xl"
    >
      <picture class="flex-shrink-0">
        <source
          :srcset="
            `https://static.snackstoppen.se/snackstoppen/brands/${brand.image}.webp`
          "
          type="image/webp"
        />
        <img
          class="w-24 ml-2 sm:ml-0 sm:w-28 sm:mb-4 sm:left-auto"
          :src="
            `https://static.snackstoppen.se/snackstoppen/png/brands/${brand.image}.png`
          "
          :alt="brand.name"
        />
      </picture>
      <div
        class="space-y-1 sm:space-y-0 text-left sm:text-center tracking-wider"
      >
        <p class="text-md sm:text-md font-semibold text-gray-700">
          {{ brand.name }}
        </p>
        <p class="text-md sm:text-sm font-semibold text-gray-400">
          {{ brand.count }} sorter
        </p>
      </div>
      <!-- BRAND-RATING -->
      <!-- <div class="flex flex-row items-center space-x-1">
        <span class="material-icons text-yellow-400">star</span>
        <p class="text-gray-400 text-sm">3,7</p>
      </div> -->
    </div>
  </internal-link>
</template>
<script>
export default {
  props: ["brand"],
};
</script>
