<template>
  <header
    class="fixed md:static z-20 top-0 w-screen md:w-auto bg-gradient-to-tr from-blue-700 to-blue-400 text-white md:rounded-xl md:mx-6 md:mt-6 mb-8 mt-0 p-3 nav-shadow"
  >
    <div class="flex justify-between items-center">
      <!-- LEFT SIDE -->
      <div class="flex-1 flex items-center">
        <!-- DESKTOP LOGO -->
        <internal-link :to="{ name: 'Home' }">
          <div
            class="font-semibold tracking-widest uppercase hidden md:block select-none ml-2 mr-6"
          >
            Snackstoppen
          </div>
        </internal-link>
        <menu-button
          class="md:hidden cursor-pointer"
          @click.native="toggle = !toggle"
          :active="toggle"
        />
      </div>
      <!-- DESKTOP -->
      <nav
        class="hidden md:flex justify-center md:space-x-6 font-mono font-bold uppercase tracking-widest"
      >
        <internal-link
          v-for="item in nav"
          :key="item.name"
          :to="item.to"
          class="hover:bg-white hover:bg-opacity-20 active:bg-opacity-40 py-1 px-2 rounded transition"
          >{{ item.name }}</internal-link
        >
      </nav>
      <!-- MOBILE LOGO -->
      <internal-link to="/">
        <div
          class="font-semibold tracking-widest uppercase md:hidden select-none"
        >
          Snackstoppen
        </div>
      </internal-link>
      <!-- RIGHT SIDE -->
      <div class="flex items-center space-x-2 flex-1 justify-end">
        <button
          @click="$root.overlay = 'search'"
          class="p-1 rounded-md hover:bg-white hover:bg-opacity-20 active:bg-opacity-40 transition"
        >
          <search-icon class="block h-6" />
        </button>
        <!-- USER -->
        <template v-if="$root.user">
          <button
            @click="$root.overlay = 'account'"
            class="p-1 md:px-2 rounded-md hover:bg-white hover:bg-opacity-20 active:bg-opacity-40 transition"
          >
            <span
              class="hidden font-semibold tracking-wider lg:inline-block align-middle mr-1"
              >{{ $root.user.username }}</span
            >
            <user-circle-icon class="inline-block align-middle h-7" />
          </button>
        </template>
        <template v-else>
          <button
            class="hidden md:block px-2 py-1 bg-blue-300 text-blue-700 h-full uppercase rounded-lg font-bold tracking-widest transition hover:bg-opacity-75"
            @click="$root.overlay = 'login'"
          >
            Logga in
          </button>
          <button
            class="md:hidden p-1 rounded-md hover:bg-white hover:bg-opacity-20 active:bg-opacity-40 transition"
            @click="$root.overlay = 'login'"
          >
            <user-icon class="block h-6" />
          </button>
        </template>
      </div>
    </div>
    <!-- MOBILE -->
    <expand :show="toggle" :duration="0.5">
      <nav
        ref="mobile-nav"
        class="md:hidden h-screen flex flex-col justify-center space-y-2 pb-44"
      >
        <internal-link
          v-for="item in nav"
          :key="item.name"
          :to="item.to"
          class="block py-2 px-2 rounded-md text-3xl font-mono font-bold text-center text-white uppercase tracking-widest active:bg-white active:bg-opacity-20"
          >{{ item.name }}</internal-link
        >
      </nav>
    </expand>
  </header>
</template>
<script>
import MenuButton from "./MenuButton";
import Expand from "./Expand";
export default {
  data: () => ({
    toggle: false,
    nav: [
      { name: "Hem", to: { name: "Home" } },
      { name: "Chips", to: { name: "Chips" } },
      { name: "Toppen", to: { name: "Top" } },
      { name: "Om sidan", to: { name: "About" } },
    ],
  }),
  created() {
    this.$root.$on("push", () => (this.toggle = false));
  },
  components: {
    MenuButton,
    Expand,
  },
};
</script>

<style scoped>
.nav-shadow {
  box-shadow: 0 0.2rem 0.75rem #1d4ed8aa;
}

.nav-enter-active,
.nav-leave-to {
  height: 0px;
  transition: height 0.2s;
}
.nav-enter-to,
.nav-leave {
  height: var(--mobile-nav-height);
}
</style>
