<template>
  <div id="app">
    <navbar />
    <transition
      :name="$root.animate ? 'fade' : ''"
      @after-enter="$root.animate = false"
    >
      <router-view class="mt-20 md:mt-0" :key="$route.path" />
    </transition>
    <transition name="overlay" tag="div" class="z-30">
      <login v-if="$root.overlay == 'login'" />
      <search v-else-if="$root.overlay == 'search'" ref="search" />
      <signup v-else-if="$root.overlay == 'signup'" />
      <account v-else-if="$root.overlay == 'account'" />
    </transition>
    <alerts :alerts="$root.alerts" />
    <confirm :confirms="$root.confirms" />
  </div>
</template>
<script>
import Alerts from "@/components/Alerts";
import Navbar from "@/components/Navbar";
import Account from "@/components/Account";
import Login from "@/components/Login";
import Search from "@/components/Search";
import Signup from "@/components/Signup";
import Confirm from "@/components/Confirm";
export default {
  components: {
    Account,
    Navbar,
    Login,
    Search,
    Signup,
    Alerts,
    Confirm,
  },
  metaInfo: {
    titleTemplate: "%s - Snackstoppen",
  },
};
</script>
