<template>
  <div class="overlay-bg flex items-center" @click="$root.overlay = null">
    <div
      class="w-96 relative bg-white p-8 m-auto rounded-xl max-w-full"
      @click.stop
    >
      <template v-if="!token">
        <close-button class="absolute right-0 top-0 mt-8 mr-8" />
        <form class="space-y-4 flex flex-col content-center">
          <h1 class="text-3xl font-semibold">Skapa konto</h1>
          <input
            class="bg-gray-50"
            placeholder="e-post"
            type="email"
            v-model="email"
          />
          <input
            class="bg-gray-50"
            placeholder="användarnamn"
            type="text"
            ref="username"
            v-model="username"
          />
          <input
            class="bg-gray-100"
            type="password"
            placeholder="lösenord"
            ref="password"
            v-model="password"
          />
          <button
            class="btn btn-shadow btn-blue-gradient"
            type="submit"
            @click.prevent="submit"
          >
            {{ submitting ? "Skapar konto..." : "Skapa konto" }}
          </button>
        </form>
      </template>
      <template v-else>
        <form class="space-y-4 flex flex-col content-center">
          <h1 class="text-3xl font-semibold">Verifiera e-post</h1>
          <p>En verifieringskod har skickats till {{ email }}.</p>
          <input
            class="bg-gray-100 py-2 px-4 rounded-lg border-2 outline-none border-gray-200 hover:border-gray-300 focus:border-blue-400 transition"
            placeholder="Verifieringskod"
            type="number"
            v-model="code"
          />
          <button
            class="block px-6 py-2 bg-white text-sm rounded-lg font-semibold tracking-widest border-2 hover:border-blue-400 transition"
            type="submit"
            @click.prevent="createAccount"
          >
            {{ submitting ? "Skapar konto..." : "Skapa konto" }}
          </button>
        </form>
      </template>
    </div>
  </div>
</template>
<script>
import CloseButton from "@/components/CloseButton.vue";

export default {
  components: { CloseButton },

  data: () => ({
    submitting: false,
    token: null,
    code: null,
    email: null,
    username: null,
    password: null,
  }),
  methods: {
    async submit() {
      this.submitting = true;
      try {
        this.token = await this.$root.verifyEmail(this.email);
      } catch (err) {
        this.$root.alert("E-postadressen är redan registrerad");
        console.error(err);
      }
      this.submitting = false;
    },
    async createAccount() {
      let user = {
        email: this.email,
        username: this.username,
        password: this.password,
      };
      this.submitting = true;
      try {
        await this.$root.createUser(user, this.token, this.code);
        this.$root.overlay = null;
      } catch (err) {
        this.$root.alert("Kunde inte skapa konto");
        console.error(err);
      }
      this.submitting = false;
    },
  },
};
</script>
