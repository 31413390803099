<template>
  <div class="container mx-auto flex flex-col items-center px-6 pb-8 space-y-8">
    <back-button class="self-start" />
    <div
      v-if="chip"
      class="
        flex flex-col
        md:flex-row
        w-128
        max-w-full
        sm:max-w-none
        md:w-auto
        space-y-12
        md:space-y-0 md:space-x-16
        items-center
      "
    >
      <img-load
        :src="
          `https://static.snackstoppen.se/snackstoppen/snacks/${chip.image}.webp`
        "
        :src-small="
          `https://static.snackstoppen.se/snackstoppen/sm/snacks/${chip.image}.webp`
        "
        :src-legacy="
          `https://static.snackstoppen.se/snackstoppen/png/snacks/${chip.image}.png`
        "
        error="/img/chips/placeholder.svg"
        :imgAlt="chip.name"
        imgClass="h-80"
      />
      <div class="flex flex-col self-start md:self-auto md:max-w-112 space-y-4">
        <div>
          <internal-link :to="/chips/ + chip.brand.id">
            <h4
              class="
                text-xl
                font-semibold
                text-gray-400
                uppercase
                tracking-wider
              "
            >
              {{ chip.brand.name }}
            </h4>
          </internal-link>
          <h2 class="text-4xl font-semibold">{{ chip.name }}</h2>
        </div>
        <a href="#review" @click="reviewOpen = true">
          <div class="flex items-center space-x-2">
            <custom-star-icon
              class="text-yellow-400 h-12 inline-block align-middle"
            />
            <div>
              <span class="text-4xl font-semibold tracking-wider align-middle"
                >{{ (chip.rating / 2).toString().substring(0, 3) }}
              </span>
              <span class="text-xl text-gray-400 align-middle">
                ({{ chip.reviews }}
                {{ chip.reviews == 1 ? "röst" : "röster" }})</span
              >
            </div>
          </div>
        </a>
        <div v-if="chip.ingredients">
          <span
            @click="ingredientsVisible = !ingredientsVisible"
            class="cursor-pointer"
          >
            <h4 class="inline align-middle text-xl font-semibold">
              Ingredienser
            </h4>
          </span>
          <transition name="overlay" appear>
            <div
              v-if="ingredientsVisible"
              @click="ingredientsVisible = !ingredientsVisible"
              class="overlay fixed z-40 top-0 right-0 left-0 bottom-0 flex items-center justify-center"
            >
              <div
                v-if="ingredientsVisible"
                @click.stop
                class="w-128 max-w-full flex flex-col space-y-4 bg-white rounded-xl p-8 mx-2 shadow-2xl"
              >
                <h3 class="font-semibold text-2xl">Ingredienser</h3>
                <p>{{ chip.ingredients }}</p>
                <p class="text-sm italic text-gray-400">
                  Ingredienserna kan ha förändrats. Utgå ifrån vad som står på
                  förpackningen.
                </p>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <div class="h-auto max-w-full w-128">
      <form
        class="bg-white p-6 rounded-xl shadow-md scroll-mt-5 sm:scroll-mt-1"
        id="review"
        @submit.prevent="submit"
      >
        <span
          @click="reviewOpen = !reviewOpen"
          class="cursor-pointer select-none"
        >
          <h3 for="review" class="inline align-middle text-2xl font-semibold">
            Ge ett omdöme
          </h3>
          <chevron-right-icon
            class="h-8 inline-block"
            :class="{ 'transform rotate-90': reviewOpen }"
          />
        </span>
        <expand :show="reviewOpen">
          <div class="space-y-4 pt-4">
            <p class="text-gray-400">Klicka eller dra för att betygsätta!</p>
            <stars setRating="0" ref="reviewRating" />
            <textarea
              name="review"
              rows="3"
              maxlength="800"
              placeholder="Skriv din recension här... (valfritt)"
              ref="review"
              class="
            bg-gray-50
            w-full
            p-4
            resize-y
            outline-none
          "
            >
            </textarea>
            <div class="flex space-x-4">
              <button
                type="submit"
                class="btn btn-blue-gradient flex-grow"
              >
                {{ submitting ? "Skickar..." : "Skicka" }}
              </button>
              <button
                type="button"
                class="
              btn
              flex-grow
              bg-white
              text-gray-400
              border-2
              hover:text-red-400
              hover:border-red-400
              transition
            "
              >
                Avbryt
              </button>
            </div>
          </div>
        </expand>
      </form>
      <h2 v-if="reviews != '' || newReview" class="font-semibold text-2xl mt-8">
        Omdömen
      </h2>
      <review v-if="newReview" :review="newReview" :chips-id="chip.id" />
      <template v-if="reviews != ''">
        <review
          v-for="review in reviews"
          :review="review"
          :key="review.id"
          :chips-id="chip.id"
        />
      </template>
      <template v-else-if="!newReview">
        <p class="text-gray-400 w-64 mx-auto text-center mt-8">
          Här var det tomt...<br />Blir du möjligtvis författaren av den första
          recensionen?
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Stars from "@/components/Stars.vue";
import ImgLoad from "@/components/ImgLoad.vue";
import Review from "@/components/Review.vue";
import Expand from "@/components/Expand.vue";
import BackButton from "@/components/BackButton.vue";
export default {
  name: "Chip",
  components: {
    Stars,
    ImgLoad,
    Review,
    Expand,
    BackButton,
  },
  data: () => ({
    chip: null,
    newReview: null,
    submitting: false,
    ingredientsVisible: false,
    reviewOpen: true,
  }),
  apollo: {
    chip: {
      query: gql`
        query ChipMessage($slug: String!, $brand: String!) {
          chip(slug: $slug, brand: $brand) {
            id
            brand {
              name
              id
            }
            slug
            rating
            reviews
            image
            name
            ingredients
          }
        }
      `,
      variables() {
        return {
          brand: this.$route.params.brandSlug,
          slug: this.$route.params.chipSlug,
        };
      },
      fetchPolicy: "cache-and-network",
    },
    reviews: {
      query: gql`
        query ChipReviewMessage($chips: Int) {
          reviews(chips: $chips) {
            id
            rating
            review
            likes
            liked
            user {
              id
              username
              firstname
              lastname
              image
            }
            created
            edited
          }
        }
      `,
      variables() {
        return {
          chips: this.chip.id,
        };
      },
      skip() {
        return this.chip == null;
      },
      fetchPolicy: "cache-and-network",
    },
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.reviewRating.rating == 0) {
          this.$root.alert("Betyg saknas");
          return;
        }
        this.submitting = true;
        var review = await this.$root.createReview({
          rating: this.$refs.reviewRating.rating,
          review: this.$refs.review.value,
          chips: this.chip.id,
        });
        this.newReview = review;
        this.$refs.review.value = null;
        this.$root.alert("Omdömet har skickats!", "green");
        this.reviewOpen = false;
      } catch (err) {
        this.$root.alert("Kunde inte skicka omdöme", "red");
        console.error(err);
      }
      this.submitting = false;
    },
  },
  metaInfo() {
    return {
      title: this.chip ? this.chip.name : "",
    };
  },
};
</script>
<style scoped>
.overlay {
  background-color: #0006;
}
</style>
