<template>
  <div class="overlay-bg flex items-center" @click="$root.overlay = null">
    <div
      class="relative w-96 max-w-full bg-white p-8 m-auto rounded-xl"
      @click.stop
    >
      <close-button class="absolute right-0 top-0 mt-8 mr-8" />
      <form class="space-y-4 flex flex-col content-center">
        <h1 class="text-3xl font-semibold">Logga in</h1>
        <input
          class="bg-gray-50"
          placeholder="användarnamn / e-post"
          type="email"
          ref="email"
        />
        <input
          class="bg-gray-50"
          type="password"
          placeholder="lösenord"
          ref="password"
        />
        <button
          class="btn btn-shadow btn-blue-gradient"
          type="submit"
          @click.prevent="login"
        >
          {{ loggingIn ? "Loggar in..." : "Logga in" }}
        </button>
        <p class="text-gray-400">
          Ny i kvarteret?
          <a
            class="text-gray-700 hover:underline cursor-pointer"
            @click="$root.overlay = 'signup'"
            >Skapa ett konto här!</a
          >
        </p>
      </form>
    </div>
  </div>
</template>
<script>
import CloseButton from "@/components/CloseButton.vue";
export default {
  components: { CloseButton },
  data: () => ({
    loggingIn: false,
  }),
  methods: {
    async login() {
      this.loggingIn = true;
      try {
        await this.$root.login(
          this.$refs.email.value,
          this.$refs.password.value
        );
        this.$refs.email.value = "";
        this.$refs.password.value = "";
        this.$root.overlay = null;
        this.$root.alert("Du har loggats in", "green");
      } catch (err) {
        this.$root.alert("Fel användarnamn eller lösenord");
        console.error(err);
      }
      this.loggingIn = false;
    },
  },
};
</script>
