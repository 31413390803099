<template>
  <div class="container max-w-240 mx-auto space-y-8 px-6 mb-20" ref="page">
    <page-heading title="Hem" />
    <div class="flex flex-wrap justify-center space-x-4 sm:space-x-8">
      <internal-link
        to="/chips"
        class="responsive-card bg-white sm:w-36 flex-grow sm:flex-grow-0 p-8 space-y-2 rounded-lg"
      >
        <img
          src="@/assets/potato-chips-2.png"
          class="w-16 sm:w-24 mx-auto opacity-75"
        />
        <p class="text-xl text-center font-semibold">Chips</p>
      </internal-link>
      <internal-link
        to="toppen"
        class="responsive-card bg-white sm:w-36 flex-grow sm:flex-grow-0 p-8 space-y-2 rounded-lg"
      >
        <img
          src="@/assets/leaderboard.png"
          class="w-16 sm:w-24 mx-auto opacity-75"
        />
        <p class="text-xl text-center font-semibold">Toppen</p>
      </internal-link>
    </div>
  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import gql from "graphql-tag";
export default {
  components: { PageHeading },
  name: "Home",
  metaInfo: {
    title: "Snackstoppen",
    // override the parent template and just use the above title only
    titleTemplate: null,
  },
  apollo: {
    review: {
      query: gql`
        query HomeReview {
          review(id: 52) {
            id
            rating
            review
            likes
            liked
            user {
              id
              username
              firstname
              lastname
              image
            }
            created
            edited
            chips {
              id
              name
              rating
              brand {
                name
                id
                image
              }
            }
          }
        }
      `,
    },
  },
};
</script>
