<template>
  <div class="fixed top-0 left-0 w-full z-50 pointer-events-none">
    <div
      class="relative text-white w-128 max-w-full m-auto mt-3 pointer-events-auto"
    >
      <transition-group name="alert">
        <div
          v-for="alert in alerts"
          :key="alert.id"
          class="bg-white alert mb-3 mx-2 py-3 px-4 flex items-center space-x-3 rounded-lg shadow-lg"
          :class="{ 'text-gray-400': !alert.color }"
        >
          <p v-if="alert.color == 'red'" class="text-xl">❗</p>
          <p v-if="alert.color == 'green'" class="text-xl">✅</p>
          <p class="text-lg text-gray-500 flex-grow">{{ alert.message }}</p>
          <x-icon
            class="text-gray-400 flex-shrink-0 cursor-pointer h-full"
            width="20"
            @click="remove(alert.id)"
          />
        </div>
      </transition-group>
    </div>
  </div>
</template>
<script>
export default {
  props: ["alerts"],
  methods: {
    remove(id) {
      let index = this.alerts.findIndex(x => x.id == id);
      if (index >= 0) this.alerts.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.alert {
  transition: all 0.3s;
  width: calc(100% - 1rem);
}
.alert-enter-active {
  transition: opacity 0.3s;
}
.alert-leave-active {
  position: absolute;
  transition: all 0.3s;
}
.alert-enter,
.alert-leave-to {
  opacity: 0;
}
</style>
