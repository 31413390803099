<template>
  <internal-link
    class="max-w-full"
    :to="{
      name: 'Chip',
      params: { chipSlug: chip.slug, brandSlug: chip.brand.id },
    }"
  >
    <div
      class="responsive-card mb-4 mt-6 w-96 max-w-full h-28 px-6 flex items-center space-x-6 bg-white rounded-xl"
    >
      <picture class="h-28 min-w-16 mb-10 flex-shrink-0">
        <source
          :srcset="
            `https://static.snackstoppen.se/snackstoppen/sm/snacks/${chip.image}.webp`
          "
          type="image/webp"
        />
        <img
          :src="
            `https://static.snackstoppen.se/snackstoppen/png/snacks/${chip.image}.png`
          "
          :alt="chip.name"
          class="h-full mx-auto"
          onerror="this.onerror=null; this.parentElement.querySelectorAll('source').forEach(el=>el.remove()); this.src='/img/chips/placeholder.svg'"
        />
      </picture>
      <div class="font-semibold">
        <p class="text-md">{{ chip.name }}</p>
        <span class="text-sm tracking-wider">
          <span v-if="subCat" class="text-gray-400 align-middle mr-2">{{
            chip.subcategory
          }}</span>
          <span v-else class="text-gray-400 align-middle mr-2">{{
            chip.brand.name
          }}</span>
          <span class="text-gray-400 align-middle mr-1">•</span>
          <span class="inline-block">
            <star-icon
              class="text-yellow-400 inline-block align-middle"
              height="20"
            />
            <span class="text-gray-400 align-middle">
              {{ (chip.rating / 2).toString().substring(0, 3) }}
            </span>
          </span>
        </span>
      </div>
    </div>
  </internal-link>
</template>
<script>
export default {
  props: { chip: Object, subCat: Boolean },
};
</script>
