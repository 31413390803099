<template>
  <div class="container mx-auto w-128 max-w-full px-6 mb-16">
    <div
      v-if="user"
      class="
        bg-white
        flex flex-col
        items-center
        rounded-xl
        shadow-md
        p-4
        space-y-4
      "
    >
      <div class="flex flex-col items-center space-y-2">
        <user-circle-icon class="h-16 sm:h-20 inline-block" />
        <h1 class="text-2xl sm:text-3xl font-semibold align-middle">
          {{ user.username }}
        </h1>
      </div>
      <button
        v-if="!$root.user || user.id != $root.user.id"
        @click="follow"
        class="btn btn-shadow btn-blue-gradient w-28"
        :class="{ follow: user.follow }"
      >
        {{ user.follow ? "Följer" : "Följ" }}
      </button>
      <div class="flex flex-row items-center space-x-1">
        <button
          @click="$root.overlay = 'following'"
          class="block p-4 rounded-lg hover:bg-gray-100 active:bg-gray-200 transition"
        >
          <p class="text-2xl">{{ user.following }}</p>
          <p>följer</p>
        </button>
        <div class="w-0.5 h-12 bg-gray-200"></div>
        <button
          @click="$root.overlay = 'followers'"
          class="block p-4 rounded-lg hover:bg-gray-100 active:bg-gray-200 transition"
        >
          <p class="text-2xl">{{ user.followers }}</p>
          <p>följare</p>
        </button>
      </div>
    </div>
    <div v-if="reviews" class="mt-5">
      <h2 class="text-2xl font-semibold">Omdömen</h2>
      <review
        v-for="review in reviews"
        :review="review"
        :key="review.id"
        :chips-id="review.chips.id"
      />
    </div>
    <transition name="overlay">
      <followers
        v-if="user && $root.overlay == 'followers'"
        :username="user.username"
      />
      <following
        v-else-if="user && $root.overlay == 'following'"
        :username="user.username"
      />
    </transition>
  </div>
</template>

<script>
import gql from "graphql-tag";
import Review from "@/components/Review";
import Following from "@/components/Following";
import Followers from "@/components/Followers";

export default {
  data: () => ({
    user: null,
    reviews: null,
  }),
  methods: {
    async follow() {
      if (this.user.follow) {
        try {
          await this.$root.unfollow(this.user);
        } catch (err) {
          this.$root.alert("Kunde inte avfölja");
          console.error(err);
        }
      } else {
        try {
          await this.$root.follow(this.user);
        } catch (err) {
          this.$root.alert("Kunde inte följa");
          console.error(err);
        }
      }
    },
  },
  apollo: {
    user: {
      query: gql`
        query UserMessage($username: String!) {
          user(username: $username) {
            id
            username
            firstname
            lastname
            image
            follow
            followers
            following
          }
        }
      `,
      variables() {
        return {
          username: this.$route.params.username,
        };
      },
      fetchPolicy: "cache-and-network",
    },
    reviews: {
      query: gql`
        query UserReviews($username: String) {
          reviews(author: $username) {
            id
            rating
            review
            likes
            liked
            created
            edited
            chips {
              id
              brand {
                name
                id
              }
              slug
              subcategory
              name
              image
              rating
              reviews
            }
          }
        }
      `,
      variables() {
        return {
          username: this.$route.params.username,
        };
      },
      fetchPolicy: "cache-and-network",
    },
  },
  components: {
    Review,
    Followers,
    Following,
  },
  metaInfo() {
    return {
      title: this.user ? this.user.username : "...",
    };
  },
};
</script>
<style scoped lang="postcss">
.follow {
  @apply bg-none bg-gray-200 text-gray-400;
}
</style>
