import { render, staticRenderFns } from "./Expand.vue?vue&type=template&id=31215164&scoped=true&"
import script from "./Expand.vue?vue&type=script&lang=js&"
export * from "./Expand.vue?vue&type=script&lang=js&"
import style0 from "./Expand.vue?vue&type=style&index=0&id=31215164&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31215164",
  null
  
)

export default component.exports