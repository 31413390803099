<template>
    <a :href="href" @click.prevent="$root.push(to)"><slot></slot></a>
</template>
<script>
export default {
    computed: {
        href() {
            return this.$router.resolve(this.to).href;
        }
    },
    props: ["to"]
}
</script>