import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Chips from "@/views/Chips.vue";
import Chip from "@/views/Chip.vue";
import Brand from "@/views/Brand.vue";
import Top from "@/views/Top.vue";
import About from "@/views/About.vue";
import User from "@/views/User.vue";
import AddSnack from "@/views/AddSnack.vue";
import Admin from "@/views/Admin.vue";
import NotFound from "@/views/NotFound.vue";

if ("scrollRestoration" in history) {
  history.scrollRestoration = "manual";
}

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/chips",
    name: "Chips",
    component: Chips,
  },
  {
    path: "/chips/:brandSlug",
    name: "Brand",
    component: Brand,
  },
  {
    path: "/chips/:brandSlug/:chipSlug",
    name: "Chip",
    component: Chip,
  },
  {
    path: "/toppen",
    name: "Top",
    component: Top,
  },
  {
    path: "/om-sidan",
    name: "About",
    component: About,
  },
  {
    path: "/user/:username",
    name: "User",
    component: User,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
  },
  {
    path: "/admin/add-snacks",
    name: "AddSnack",
    component: AddSnack,
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (router.app.$root.animate) {
      return new Promise((resolve) => {
        setTimeout(() => {
          if (savedPosition) resolve(savedPosition);
          else resolve({ x: 0, y: 0 });
        }, 250);
      });
    } else {
      if (savedPosition) return savedPosition;
      else return { x: 0, y: 0 };
    }
  },
});

export default router;
