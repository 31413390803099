<template>
  <div class="container mx-auto">
    <page-heading title="Om sidan" />
  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading"
export default {
  components: { PageHeading },
  name: "About",
};
</script>
