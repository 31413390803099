<template>
  <div class="space-x-4">
    <svg
      class="inline-block cursor-pointer select-none align-middle"
      style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0)"
      width="210"
      height="56"
      viewBox="0 0 560 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @mousemove="hover"
      @mouseleave="leave"
      @mousedown="down"
      @mouseup="up"
      @touchstart="touchStart"
      @touchmove="touchMove"
      @touchend="touchEnd"
      @contextmenu.prevent
      ref="stars"
    >
      <g
        style="transition: clip-path 0.35s"
        :style="{
          'clip-path': `inset(0 ${100 - displayRating * 10}% 0 0)`,
        }"
      >
        <rect x="0" y="0" width="560" height="112" />
        <path
          d="M50.8797 17.735C52.4939 12.755 59.5047 12.755 61.1135 17.735L66.8707 35.5352C67.2224 36.6193 67.9062 37.5639 68.8242 38.2339C69.7423 38.904 70.8476 39.2653 71.9822 39.2661H90.6097C95.8234 39.2661 97.9864 45.971 93.7734 49.053L78.7079 60.0511C77.788 60.7217 77.103 61.6679 76.7512 62.7539C76.3993 63.8398 76.3987 65.0099 76.7494 66.0963L82.5066 83.8965C84.1207 88.8765 78.4443 93.0237 74.2205 89.9417L59.155 78.9436C58.236 78.2731 57.1295 77.912 55.9939 77.912C54.8583 77.912 53.7518 78.2731 52.8329 78.9436L37.7674 89.9417C33.549 93.0237 27.8779 88.8765 29.4867 83.8965L35.2439 66.0963C35.5945 65.0099 35.5939 63.8398 35.2421 62.7539C34.8902 61.6679 34.2053 60.7217 33.2854 60.0511L18.2252 49.0584C14.0123 45.9764 16.1806 39.2715 21.389 39.2715H40.011C41.1466 39.2718 42.2531 38.9111 43.1722 38.2409C44.0913 37.5708 44.7759 36.6256 45.1279 35.5406L50.8851 17.7404L50.8797 17.735Z"
          fill="#FFE184"
        />
        <path
          d="M162.88 17.735C164.494 12.755 171.505 12.755 173.114 17.735L178.871 35.5352C179.222 36.6193 179.906 37.5639 180.824 38.2339C181.742 38.904 182.848 39.2653 183.982 39.2661H202.61C207.823 39.2661 209.986 45.971 205.773 49.053L190.708 60.0511C189.788 60.7217 189.103 61.6679 188.751 62.7539C188.399 63.8398 188.399 65.0099 188.749 66.0963L194.507 83.8965C196.121 88.8765 190.444 93.0237 186.221 89.9417L171.155 78.9436C170.236 78.2731 169.13 77.912 167.994 77.912C166.858 77.912 165.752 78.2731 164.833 78.9436L149.767 89.9417C145.549 93.0237 139.878 88.8765 141.487 83.8965L147.244 66.0963C147.595 65.0099 147.594 63.8398 147.242 62.7539C146.89 61.6679 146.205 60.7217 145.285 60.0511L130.225 49.0584C126.012 45.9764 128.181 39.2715 133.389 39.2715H152.011C153.147 39.2718 154.253 38.9111 155.172 38.2409C156.091 37.5708 156.776 36.6256 157.128 35.5406L162.885 17.7404L162.88 17.735Z"
          fill="#FFE184"
        />
        <path
          d="M274.88 17.735C276.494 12.755 283.505 12.755 285.114 17.735L290.871 35.5352C291.222 36.6193 291.906 37.5639 292.824 38.2339C293.742 38.904 294.848 39.2653 295.982 39.2661H314.61C319.823 39.2661 321.986 45.971 317.773 49.053L302.708 60.0511C301.788 60.7217 301.103 61.6679 300.751 62.7539C300.399 63.8398 300.399 65.0099 300.749 66.0963L306.507 83.8965C308.121 88.8765 302.444 93.0237 298.221 89.9417L283.155 78.9436C282.236 78.2731 281.13 77.912 279.994 77.912C278.858 77.912 277.752 78.2731 276.833 78.9436L261.767 89.9417C257.549 93.0237 251.878 88.8765 253.487 83.8965L259.244 66.0963C259.595 65.0099 259.594 63.8398 259.242 62.7539C258.89 61.6679 258.205 60.7217 257.285 60.0511L242.225 49.0584C238.012 45.9764 240.181 39.2715 245.389 39.2715H264.011C265.147 39.2718 266.253 38.9111 267.172 38.2409C268.091 37.5708 268.776 36.6256 269.128 35.5406L274.885 17.7404L274.88 17.735Z"
          fill="#FFE184"
        />
        <path
          d="M386.88 17.735C388.494 12.755 395.505 12.755 397.114 17.735L402.871 35.5352C403.222 36.6193 403.906 37.5639 404.824 38.2339C405.742 38.904 406.848 39.2653 407.982 39.2661H426.61C431.823 39.2661 433.986 45.971 429.773 49.053L414.708 60.0511C413.788 60.7217 413.103 61.6679 412.751 62.7539C412.399 63.8398 412.399 65.0099 412.749 66.0963L418.507 83.8965C420.121 88.8765 414.444 93.0237 410.221 89.9417L395.155 78.9436C394.236 78.2731 393.13 77.912 391.994 77.912C390.858 77.912 389.752 78.2731 388.833 78.9436L373.767 89.9417C369.549 93.0237 363.878 88.8765 365.487 83.8965L371.244 66.0963C371.595 65.0099 371.594 63.8398 371.242 62.7539C370.89 61.6679 370.205 60.7217 369.285 60.0511L354.225 49.0584C350.012 45.9764 352.181 39.2715 357.389 39.2715H376.011C377.147 39.2718 378.253 38.9111 379.172 38.2409C380.091 37.5708 380.776 36.6256 381.128 35.5406L386.885 17.7404L386.88 17.735Z"
          fill="#FFE184"
        />
        <path
          d="M498.88 17.735C500.494 12.755 507.505 12.755 509.114 17.735L514.871 35.5352C515.222 36.6193 515.906 37.5639 516.824 38.2339C517.742 38.904 518.848 39.2653 519.982 39.2661H538.61C543.823 39.2661 545.986 45.971 541.773 49.053L526.708 60.0511C525.788 60.7217 525.103 61.6679 524.751 62.7539C524.399 63.8398 524.399 65.0099 524.749 66.0963L530.507 83.8965C532.121 88.8765 526.444 93.0237 522.221 89.9417L507.155 78.9436C506.236 78.2731 505.13 77.912 503.994 77.912C502.858 77.912 501.752 78.2731 500.833 78.9436L485.767 89.9417C481.549 93.0237 475.878 88.8765 477.487 83.8965L483.244 66.0963C483.595 65.0099 483.594 63.8398 483.242 62.7539C482.89 61.6679 482.205 60.7217 481.285 60.0511L466.225 49.0584C462.012 45.9764 464.181 39.2715 469.389 39.2715H488.011C489.147 39.2718 490.253 38.9111 491.172 38.2409C492.091 37.5708 492.776 36.6256 493.128 35.5406L498.885 17.7404L498.88 17.735Z"
          fill="#FFE184"
        />
      </g>
      <g>
        <path
          d="M50.8797 17.735C52.4939 12.755 59.5047 12.755 61.1135 17.735L66.8707 35.5352C67.2224 36.6193 67.9062 37.5639 68.8242 38.2339C69.7423 38.904 70.8476 39.2653 71.9822 39.2661H90.6097C95.8234 39.2661 97.9864 45.971 93.7734 49.053L78.7079 60.0511C77.788 60.7217 77.103 61.6679 76.7512 62.7539C76.3993 63.8398 76.3987 65.0099 76.7494 66.0963L82.5066 83.8965C84.1207 88.8765 78.4443 93.0237 74.2205 89.9417L59.155 78.9436C58.236 78.2731 57.1295 77.912 55.9939 77.912C54.8583 77.912 53.7518 78.2731 52.8329 78.9436L37.7674 89.9417C33.549 93.0237 27.8779 88.8765 29.4867 83.8965L35.2439 66.0963C35.5945 65.0099 35.5939 63.8398 35.2421 62.7539C34.8902 61.6679 34.2053 60.7217 33.2854 60.0511L18.2252 49.0584C14.0123 45.9764 16.1806 39.2715 21.389 39.2715H40.011C41.1466 39.2718 42.2531 38.9111 43.1722 38.2409C44.0913 37.5708 44.7759 36.6256 45.1279 35.5406L50.8851 17.7404L50.8797 17.735Z"
          stroke="#FFC100"
          stroke-width="6"
        />
        <path
          d="M162.88 17.735C164.494 12.755 171.505 12.755 173.114 17.735L178.871 35.5352C179.222 36.6193 179.906 37.5639 180.824 38.2339C181.742 38.904 182.848 39.2653 183.982 39.2661H202.61C207.823 39.2661 209.986 45.971 205.773 49.053L190.708 60.0511C189.788 60.7217 189.103 61.6679 188.751 62.7539C188.399 63.8398 188.399 65.0099 188.749 66.0963L194.507 83.8965C196.121 88.8765 190.444 93.0237 186.221 89.9417L171.155 78.9436C170.236 78.2731 169.13 77.912 167.994 77.912C166.858 77.912 165.752 78.2731 164.833 78.9436L149.767 89.9417C145.549 93.0237 139.878 88.8765 141.487 83.8965L147.244 66.0963C147.595 65.0099 147.594 63.8398 147.242 62.7539C146.89 61.6679 146.205 60.7217 145.285 60.0511L130.225 49.0584C126.012 45.9764 128.181 39.2715 133.389 39.2715H152.011C153.147 39.2718 154.253 38.9111 155.172 38.2409C156.091 37.5708 156.776 36.6256 157.128 35.5406L162.885 17.7404L162.88 17.735Z"
          stroke="#FFC100"
          stroke-width="6"
        />
        <path
          d="M274.88 17.735C276.494 12.755 283.505 12.755 285.114 17.735L290.871 35.5352C291.222 36.6193 291.906 37.5639 292.824 38.2339C293.742 38.904 294.848 39.2653 295.982 39.2661H314.61C319.823 39.2661 321.986 45.971 317.773 49.053L302.708 60.0511C301.788 60.7217 301.103 61.6679 300.751 62.7539C300.399 63.8398 300.399 65.0099 300.749 66.0963L306.507 83.8965C308.121 88.8765 302.444 93.0237 298.221 89.9417L283.155 78.9436C282.236 78.2731 281.13 77.912 279.994 77.912C278.858 77.912 277.752 78.2731 276.833 78.9436L261.767 89.9417C257.549 93.0237 251.878 88.8765 253.487 83.8965L259.244 66.0963C259.595 65.0099 259.594 63.8398 259.242 62.7539C258.89 61.6679 258.205 60.7217 257.285 60.0511L242.225 49.0584C238.012 45.9764 240.181 39.2715 245.389 39.2715H264.011C265.147 39.2718 266.253 38.9111 267.172 38.2409C268.091 37.5708 268.776 36.6256 269.128 35.5406L274.885 17.7404L274.88 17.735Z"
          stroke="#FFC100"
          stroke-width="6"
        />
        <path
          d="M386.88 17.735C388.494 12.755 395.505 12.755 397.114 17.735L402.871 35.5352C403.222 36.6193 403.906 37.5639 404.824 38.2339C405.742 38.904 406.848 39.2653 407.982 39.2661H426.61C431.823 39.2661 433.986 45.971 429.773 49.053L414.708 60.0511C413.788 60.7217 413.103 61.6679 412.751 62.7539C412.399 63.8398 412.399 65.0099 412.749 66.0963L418.507 83.8965C420.121 88.8765 414.444 93.0237 410.221 89.9417L395.155 78.9436C394.236 78.2731 393.13 77.912 391.994 77.912C390.858 77.912 389.752 78.2731 388.833 78.9436L373.767 89.9417C369.549 93.0237 363.878 88.8765 365.487 83.8965L371.244 66.0963C371.595 65.0099 371.594 63.8398 371.242 62.7539C370.89 61.6679 370.205 60.7217 369.285 60.0511L354.225 49.0584C350.012 45.9764 352.181 39.2715 357.389 39.2715H376.011C377.147 39.2718 378.253 38.9111 379.172 38.2409C380.091 37.5708 380.776 36.6256 381.128 35.5406L386.885 17.7404L386.88 17.735Z"
          stroke="#FFC100"
          stroke-width="6"
        />
        <path
          d="M498.88 17.735C500.494 12.755 507.505 12.755 509.114 17.735L514.871 35.5352C515.222 36.6193 515.906 37.5639 516.824 38.2339C517.742 38.904 518.848 39.2653 519.982 39.2661H538.61C543.823 39.2661 545.986 45.971 541.773 49.053L526.708 60.0511C525.788 60.7217 525.103 61.6679 524.751 62.7539C524.399 63.8398 524.399 65.0099 524.749 66.0963L530.507 83.8965C532.121 88.8765 526.444 93.0237 522.221 89.9417L507.155 78.9436C506.236 78.2731 505.13 77.912 503.994 77.912C502.858 77.912 501.752 78.2731 500.833 78.9436L485.767 89.9417C481.549 93.0237 475.878 88.8765 477.487 83.8965L483.244 66.0963C483.595 65.0099 483.594 63.8398 483.242 62.7539C482.89 61.6679 482.205 60.7217 481.285 60.0511L466.225 49.0584C462.012 45.9764 464.181 39.2715 469.389 39.2715H488.011C489.147 39.2718 490.253 38.9111 491.172 38.2409C492.091 37.5708 492.776 36.6256 493.128 35.5406L498.885 17.7404L498.88 17.735Z"
          stroke="#FFC100"
          stroke-width="6"
        />
      </g>
    </svg>
    <span class="ml-3 align-middle relative inline-block">
      <transition name="text-fade">
        <span
          class="text-2xl font-semibold -inset-0 inline-block align-middle text-gray-600 tracking-wider"
          :key="rating"
          >{{
            rating != 0 ? (rating / 2).toString().substring(0, 3) : ""
          }}</span
        >
      </transition>
      <span class="align-middle ml-2 text-gray-400">{{
        reviews ? `(${reviews})` : ""
      }}</span>
    </span>
  </div>
</template>
<script>
const width = 210;
function calcRating(x) {
  let pos = (10 * x) / width + 0.01;
  return Math.min(
    Math.max(
      Math.ceil(
        pos +
          (pos % 2 > 1 ? (pos % 1 > 0.5 ? 0 : -0.2) : pos % 1 > 0.5 ? 0 : -0.25)
      ),
      1
    ),
    10
  );
}
export default {
  methods: {
    hover(e) {
      this.displayRating = calcRating(e.offsetX);
      if (this.mouseDown) this.rating = this.displayRating;
    },
    leave() {
      this.displayRating = this.rating;
      this.mouseDown = false;
    },
    down(e) {
      this.displayRating = calcRating(e.offsetX);
      this.rating = this.displayRating;
      this.mouseDown = true;
      e.preventDefault();
    },
    up() {
      this.mouseDown = false;
    },
    touchStart(e) {
      var x = e.touches[0].clientX;
      var y = e.touches[0].clientY;
      this.touchPos = { x, y };
      e.stopPropagation();
    },
    touchMove(e) {
      if (!this.touchDown) {
        if (!this.touchPos) return;
        var clientX = e.touches[0].clientX;
        var clientY = e.touches[0].clientY;
        if (
          Math.abs(clientX - this.touchPos.x) <
          Math.abs(clientY - this.touchPos.y)
        ) {
          this.touchPos = null;
          return;
        } else {
          this.touchDown = true;
          this.touchPos = null;
        }
      }
      e.preventDefault();
      var rect = this.$refs.stars.getBoundingClientRect();
      var x = e.touches[0].clientX - rect.left;
      this.displayRating = calcRating(x);
      this.rating = this.displayRating;
    },
    touchEnd() {
      this.touchDown = false;
    },
  },
  data: () => ({
    displayRating: 0,
    rating: 0,
    mouseDown: false,
    touchDown: false,
    touchPos: null,
  }),
  mounted() {
    window.setTimeout(() => {
      this.rating = this.setRating;
      this.displayRating = this.rating;
    }, 100);
  },
  watch: {
    setRating() {
      this.rating = this.setRating;
    },
  },
  props: ["setRating", "reviews"],
};
</script>
