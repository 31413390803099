<template>
  <h1
    class="text-3xl mb-4 text-center font-sans font-bold text-blue-500 tracking-widest uppercase"
  >
    {{ title }}
  </h1>
</template>
<script>
export default {
  props: ["title"],
};
</script>
