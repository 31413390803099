import Vue from "vue";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import vueInstance from "./main";

// Install the vue plugin
Vue.use(VueApollo);

const httpLink = new HttpLink({
  uri:
    process.env.VUE_APP_GRAPHQL_HTTP || "https://api.snackstoppen.se/graphql",
});

var refreshing = null;

const authLink = setContext(async (_, { headers, noAuth }) => {
  if (noAuth) console.log("send without auth");
  if (noAuth) return { headers };
  var token = window.localStorage.getItem("access-token");
  if (token) {
    const valid =
      window.localStorage.getItem("access-token-expires") >
      new Date().getTime() + 180000;
    if (!valid) {
      console.log("expired token");
      if (refreshing !== null) {
        console.log("waiting for token refresh");
        await refreshing;
        console.log("done waiting");
      } else {
        var resolve;
        refreshing = new Promise(res => (resolve = res));
        try {
          console.log("refreshing token");
          await vueInstance.refresh();
          resolve();
          refreshing = null;
          console.log("token refreshed");
        } catch (err) {
          resolve();
          refreshing = null;
          console.log("token refresh failed");
        }
      }
      token = window.localStorage.getItem("access-token");
    }
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    if (graphQLErrors[0].extensions && graphQLErrors[0].extensions.code)
      switch (graphQLErrors[0].extensions.code) {
        case "AUTHENTICATION_ERROR":
          console.log("unexpected invalid token");
          vueInstance.logout();
          vueInstance.alert("Du har loggats ut");
          break;
      }
  }

  if (networkError) {
    console.log(networkError);
  }
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

// Install the vue plugin
Vue.use(VueApollo);

// Create a provider
export default new VueApollo({
  defaultClient: apolloClient,
});
