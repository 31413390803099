<template>
  <div class="container max-w-240 mx-auto px-6 mb-16" ref="page">
    <back-button />
    <div>
      <picture v-if="brand">
        <source
          :srcset="
            `https://static.snackstoppen.se/snackstoppen/brands/${brand.image}.webp`
          "
          type="image/webp"
        />
        <img
          class="w-44 mb-4 mx-auto"
          :src="
            `https://static.snackstoppen.se/snackstoppen/png/brands/${brand.image}.png`
          "
          :alt="brand.name"
        />
      </picture>
      <div>
        <div
          class="bg-white w-full rounded-xl shadow-md mb-8 flex items-center justify-between p-2"
        >
          <div>
            <button
              @click="filterShow = !filterShow"
              class="hover:bg-gray-100 px-2 py-1 rounded-lg"
            >
              <span class=" text-sm font-semibold">Filtrera</span>
              <chevron-down-icon class="h-5 inline-block" />
            </button>
            <div
              v-if="filterShow"
              @click="filterShow = !filterShow"
              class="z-30 fixed w-full h-full left-0 top-0 events-none"
            ></div>
            <div
              v-if="filterShow"
              class="absolute bg-white border-2 border-gray-200 mt-4 -ml-2 px-6 py-4 rounded-lg shadow-xl z-40"
            >
              <ul class="space-y-2">
                <li v-for="category in categories" :key="category.category">
                  <input
                    type="checkbox"
                    :id="category.category"
                    :value="category.category"
                    v-model="category.checked"
                  />
                  <label
                    :for="category.category"
                    class="ml-2 select-none"
                    :class="{
                      'text-gray-400': !category.checked,
                    }"
                    >{{ category.category }} ({{ category.count }})</label
                  >
                </li>
              </ul>
            </div>
          </div>
          <p class="text-gray-400 text-sm" v-if="selectedCount">
            Visar {{ selectedCount }} sorter
          </p>
          <div>
            <button
              @click="sortShow = !sortShow"
              class="hover:bg-gray-100 px-2 py-1 rounded-lg"
            >
              <span class=" text-sm font-semibold">Sortera</span>
              <chevron-down-icon class="h-5 inline-block" />
            </button>
            <div
              v-if="sortShow"
              class="z-30 fixed w-full h-full left-0 top-0 events-none"
              @click="sortShow = !sortShow"
            ></div>
            <div
              v-if="sortShow"
              class="absolute bg-white border-2 border-gray-200 px-6 py-4 mt-4 -ml-6 rounded-lg shadow-xl z-40"
            >
              <ul class="space-y-2">
                <li>
                  <input
                    type="radio"
                    id="name"
                    value="NAME_ASC"
                    name="sort"
                    v-model="sort"
                    checked
                  />
                  <label for="name" name="sort" class="ml-2">A-Ö</label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="rating"
                    value="RATING_DESC"
                    name="sort"
                    v-model="sort"
                  />
                  <label for="rating" name="sort" class="ml-2">Betyg</label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div v-if="chips" class="flex flex-wrap justify-center">
          <chip-card
            v-for="chip in chips"
            :key="chip.id"
            :chip="chip"
            subCat
            class="sm:mx-4"
          >
          </chip-card>
        </div>
        <infinite-scroll :callback="load" />
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import ChipCard from "@/components/ChipCard.vue";
import InfiniteScroll from "@/components/InfiniteScroll";
import BackButton from "@/components/BackButton.vue";
const amount = 12;
export default {
  components: { ChipCard, InfiniteScroll, BackButton },
  name: "Brand",
  data: () => ({
    page: 0,
    show: amount,
    chips: null,
    full: false,
    count: 0,
    filterShow: false,
    sortShow: false,
    categories: null,
    sort: "NAME_ASC",
  }),
  methods: {
    load() {
      if (this.$apollo.queries.chips.loading || this.full) return;
      if (this.chips && this.chips.length > this.show) {
        this.page++;
        this.show = amount * (this.page + 1);
        return;
      }
      if ((this.chips ? this.chips.length : 0) < this.show) return;
      this.page++;
      this.show = amount * (this.page + 1);
      this.$apollo.queries.chips.fetchMore({
        variables: {
          offset: this.page * amount,
          amount,
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (fetchMoreResult.chips.length == 0) this.full = true;
          return {
            chips: [...previousResult.chips, ...fetchMoreResult.chips],
          };
        },
      });
    },
  },
  apollo: {
    brand: {
      query: gql`
        query BrandMessage($id: String!) {
          brand(id: $id) {
            id
            name
            image
            count
            categories
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.brandSlug,
        };
      },
    },
    chips: {
      query: gql`
        query BrandChipsMessage(
          $limit: Int
          $brand: String
          $offset: Int
          $order_by: ChipSortByInput
          $subcategory: [String]
        ) {
          chips(
            limit: $limit
            order_by: $order_by
            brand: $brand
            offset: $offset
            subcategory: $subcategory
          ) {
            id
            brand {
              name
              id
            }
            slug
            subcategory
            name
            image
            rating
            reviews
          }
        }
      `,
      variables() {
        return {
          limit: amount,
          brand: this.$route.params.brandSlug,
          offset: 0,
          order_by: this.sort,
          subcategory: this.selectedSubcategories,
        };
      },
    },
  },
  watch: {
    chipsSlice(value) {
      if (value && !this.full) {
        this.$nextTick(() => {
          if (this.$refs.page.scrollHeight <= window.innerHeight) {
            this.load();
          }
        });
      }
    },
    brand(value) {
      if (!value) {
        categories = null;
        return;
      }
      let categories = JSON.parse(value.categories);
      this.categories = Object.entries(categories.Chips)
        .map(x => ({
          category: x[0],
          count: x[1],
          checked: false,
        }))
        .sort((a, b) => (a.count < b.count ? 1 : -1));
    },
  },
  computed: {
    chipsSlice() {
      if (this.chips) return this.chips.slice(0, this.show);
      else return null;
    },
    selectedSubcategories() {
      if (!this.categories) return null;
      let selected = this.categories
        .filter(x => x.checked)
        .map(x => x.category);
      if (selected.length == 0) return null;
      return selected;
    },
    selectedCount() {
      if (!this.categories) return null;
      if (!this.selectedSubcategories) {
        return this.categories.reduce((total, x) => total + x.count, 0);
      }
      return this.categories
        .filter(x => x.checked)
        .reduce((total, x) => total + x.count, 0);
    },
  },
  metaInfo() {
    return {
      title: this.brand ? this.brand.name : "",
    };
  },
};
</script>
