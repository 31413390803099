<template>
  <internal-link
    class="max-w-full"
    :to="{
      name: 'Chip',
      params: { chipSlug: chip.slug, brandSlug: chip.brand.id },
    }"
  >
    <div
      class="responsive-card mt-6 w-128 max-w-full h-28 pr-6 pl-4 sm:pl-6 flex items-center space-x-4 sm:space-x-6 bg-white rounded-xl"
    >
      <p class="text-xl font-semibold">{{ count }}</p>
      <picture class="flex-shrink-0">
        <source
          :srcset="
            `https://static.snackstoppen.se/snackstoppen/sm/snacks/${chip.image}.webp`
          "
          type="image/webp"
        />
        <img
          :src="
            `https://static.snackstoppen.se/snackstoppen/png/snacks/${chip.image}.png`
          "
          :alt="chip.name"
          class="h-20 sm:h-28 sm:mb-10"
          onerror="this.onerror=null; this.parentElement.querySelectorAll('source').forEach(el=>el.remove()); this.src='/img/chips/placeholder.svg'"
        />
      </picture>
      <div class="font-semibold leading-tight sm:leading-normal flex-grow">
        <p>{{ chip.name }}</p>
        <p class="text-gray-400 text-sm tracking-wider">
          {{ chip.brand.name }}
        </p>
      </div>
      <div>
        <div class="flex flex-row space-x-1 items-center">
          <star-icon class="h-8 text-yellow-400" />
          <span class="text-lg font-semibold">
            {{ (chip.rating / 2).toString().substring(0, 3) }}
          </span>
        </div>
        <p class="text-sm font-semibold text-gray-400 text-right">
          {{ chip.reviews }} {{ chip.reviews == 1 ? "röst" : "röster" }}
        </p>
      </div>
    </div>
  </internal-link>
</template>
<script>
export default {
  props: { chip: Object, subCat: Boolean, count: Number },
};
</script>
