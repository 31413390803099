<template>
  <div class="relative">
    <img v-if="notFound" :src="error" class="max-w-none" :class="imgClass" />
    <template v-else>
      <picture>
        <source :srcset="srcSmall" type="image/webp" />
        <img
          :src="srcLegacy"
          class="max-w-none small"
          :class="[imgClass, { 'show-small': loading }]"
          @error="notFound = true"
          :alt="imgAlt"
        />
      </picture>
      <img
        class="max-w-none large"
        :class="[imgClass, { 'show-large': !loading }]"
        @load="loading = false"
        @error="notFound = true"
        ref="img"
        :alt="imgAlt"
      />
    </template>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: true,
    notFound: false,
  }),
  props: ["src", "srcSmall", "imgClass", "error", "imgAlt", "srcLegacy"],
  mounted() {
    var img = new Image();
    img.onload = () => {
      this.$refs.img.src = this.src;
    };
    img.src = this.src;
  },
};
</script>
<style scoped>
.small {
  transition: visibility 0.15s, opacity 0.1s 0.05s ease-out;
  position: absolute;
  visibility: hidden;
  opacity: 0;
}
.show-small {
  visibility: visible;
  position: static;
  opacity: 1;
}
.large {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
}
.show-large {
  position: static;
  visibility: visible;
  opacity: 1;
}
</style>