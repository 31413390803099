<template>
  <div class="container max-w-240 mx-auto px-6">
    <back-button />
    <page-heading title="Chipsmärken" />
    <div class="mx-auto">
      <div
        class="flex flex-wrap justify-center items-center my-8"
        v-if="brands"
      >
        <brand-card v-for="brand in brands" :key="brand._id" :brand="brand" />
      </div>
    </div>
    <page-heading class="mt-16" title="Chipssorter" />
    <div class="mx-auto space-y-8 mt-8 mb-20">
      <div v-if="chips" class="flex flex-wrap justify-center">
        <chip-card
          v-for="chip in chipsSlice"
          class="sm:mx-4"
          :key="chip.id"
          :chip="chip"
        >
        </chip-card>
      </div>
      <div
        class="text-center text-gray-400 my-3"
        v-if="
          chips && show > chips.length && this.$apollo.queries.chips.loading
        "
      >
        Laddar...
      </div>
      <infinite-scroll :callback="load" />
    </div>
  </div>
</template>
<script>
import BrandCard from "@/components/BrandCard.vue";
import PageHeading from "@/components/PageHeading.vue";
import gql from "graphql-tag";
import BackButton from "@/components/BackButton.vue";
import ChipCard from "@/components/ChipCard";
import InfiniteScroll from "@/components/InfiniteScroll";
const amount = 12;

export default {
  components: { BrandCard, PageHeading, BackButton, ChipCard, InfiniteScroll },
  name: "Chips",

  data: function() {
    return {
      brands: null,
      page: 0,
      show: amount,
      chips: null,
      full: false,
    };
  },
  methods: {
    load() {
      if (this.$apollo.queries.chips.loading || this.full) return;
      if (this.chips && this.chips.length > this.show) {
        this.page++;
        this.show = amount * (this.page + 1);
        return;
      }
      if ((this.chips ? this.chips.length : 0) < this.show) return;
      this.page++;
      this.show = amount * (this.page + 1);
      this.$apollo.queries.chips.fetchMore({
        variables: {
          offset: this.page * amount,
          amount,
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (fetchMoreResult.chips.length == 0) this.full = true;
          return {
            chips: [...previousResult.chips, ...fetchMoreResult.chips],
          };
        },
      });
    },
  },
  apollo: {
    brands: {
      query: gql`
        query Brands {
          brands(order_by: NAME_ASC) {
            id
            name
            image
            count
          }
        }
      `,
    },
    chips: {
      query: gql`
        query HomeChipsMessage($limit: Int, $offset: Int) {
          chips(limit: $limit, order_by: NAME_ASC, offset: $offset) {
            id
            brand {
              name
              id
            }
            slug
            name
            image
            subcategory
            rating
            reviews
          }
        }
      `,
      variables: {
        offset: 0,
        limit: amount,
      },
    },
  },
  watch: {
    chipsSlice(value) {
      if (value && !this.full) {
        this.$nextTick(() => {
          if (this.$refs.page.scrollHeight <= window.innerHeight) {
            this.load();
          }
        });
      }
    },
  },
  computed: {
    chipsSlice() {
      if (this.chips) return this.chips.slice(0, this.show);
      else return null;
    },
  },
  metaInfo: {
    title: "Chipsmärken",
  },
};
</script>
