<template>
  <div class="container mx-auto px-6 mb-8">
    <page-heading title="Lägg till snacks" />
    <form class="flex flex-col mx-auto w-full sm:w-96" @submit.prevent="submit">
      <label class="mt-2">Namn</label
      ><input
        class="input-field text-input"
        type="text"
        v-model="snack.name"
        placeholder="Namn"
      />
      <label>Märke</label
      ><select class="input-field text-input" v-model="snack.brand">
        <option disabled hidden value="undefined">Märke</option>
        <option v-for="brand in brands" value="brand.id" :key="brand.id">{{
          brand.name
        }}</option>
      </select>
      <label>Slug</label
      ><input
        class="input-field"
        type="text"
        v-model="snack.slug"
        placeholder="Slug"
      />
      <label>Kategori</label
      ><input
        class="input-field"
        type="text"
        v-model="snack.category"
        placeholder="Kategori"
      />
      <label>Subkategori</label
      ><input
        class="input-field"
        type="text"
        v-model="snack.subcategory"
        placeholder="Subkategori"
      />
      <label>Ingredienser</label
      ><textarea
        class="input-field"
        v-model="snack.ingredients"
        placeholder="Ingredienser"
      ></textarea>
      <button
        type="submit"
        class="bg-blue-500 text-white font-semibold py-3 mt-4 rounded-lg"
      >
        Skicka
      </button>
    </form>
  </div>
</template>
<script>
import gql from "graphql-tag";
import slugify from "slugify";
import PageHeading from "@/components/PageHeading.vue";
export default {
  components: { PageHeading },
  data: () => ({
    brands: [],
    suggestedSlug: "",
    snack: {},
  }),
  created() {
    slugify.extend({ "&": "och" });
    slugify.extend({ "❤": "love" });
  },
  watch: {
    "snack.name"(current) {
      if (this.snack.slug == this.suggestedSlug || !this.snack.slug) {
        let slug = slugify(current, {
          lower: true,

          strict: true,
          locale: "sv",
        });
        this.suggestedSlug = slug;
        this.snack.slug = slug;
      }
    },
  },
  methods: {
    async submit() {
      try {
        await this.$root.createSnack(this.snack);
        this.snack = {
          brand: "default",
        };
      } catch (err) {
        this.$root.alert("Kunde inte lägga till snacks");
        console.error(err);
      }
    },
  },
  apollo: {
    brands: {
      query: gql`
        query CreateSnackBrands {
          brands(order_by: NAME_ASC) {
            id
            name
            image
            count
          }
        }
      `,
    },
  },
};
</script>
<style scoped>
.input-field {
  @apply mt-2
        mb-4;
}
</style>
