<template>
  <div
    class="bg-white my-4 p-6 space-y-2 rounded-xl shadow-md"
    v-if="review.rating !== -1"
  >
    <div class="flex">
      <div class=" space-y-2 flex-grow">
        <internal-link
          v-if="!review.user && review.chips"
          :to="{
            name: 'Chip',
            params: {
              chipSlug: review.chips.slug,
              brandSlug: review.chips.brand.id,
            },
          }"
        >
          <div
            class="flex items-center space-x-4 active:bg-gray-100 rounded-lg"
          >
            <picture>
              <source
                :srcset="
                  `https://static.snackstoppen.se/snackstoppen/sm/snacks/${review.chips.image}.webp`
                "
                type="image/webp"
              />
              <img
                :src="
                  `https://static.snackstoppen.se/snackstoppen/png/snacks/${review.chips.image}.png`
                "
                :alt="review.chips.name"
                class="h-12"
                onerror="this.onerror=null; this.parentElement.querySelectorAll('source').forEach(el=>el.remove()); this.src='/img/chips/placeholder.svg'"
              />
            </picture>
            <div>
              <h3 class="font-semibold">{{ review.chips.name }}</h3>
              <h4 class="text-sm font-semibold text-gray-400">
                {{ review.chips.brand.name }}
              </h4>
            </div>
          </div>
        </internal-link>
        <div>
          <template v-if="review.user">
            <internal-link
              :to="{
                name: 'User',
                params: {
                  username: review.user.username,
                },
              }"
            >
              <span class="mr-2">
                <user-circle-icon class="inline-block align-middle mr-1 h-8" />
                <h3 class="font-semibold inline-block align-middle">
                  {{ review.user.username }}
                </h3>
              </span>
            </internal-link>
            <span class="text-gray-400 align-middle mr-2">•</span>
          </template>
          <span class="text-gray-400 inline-block align-middle mr-2">{{
            formatDate(review.created)
          }}</span>
          <span class="text-gray-400 align-middle mr-2">•</span>
          <span class="inline-block">
            <star-icon
              v-for="rating in Math.floor(review.rating / 2)"
              :key="rating"
              class="text-yellow-400 align-middle h-6 -mx-0.5 inline-block align-middle"
            />
            <half-star-icon
              v-if="review.rating % 2 == 1"
              class="align-middle h-6 -mx-0.5 inline-block align-middle"
            />
            <star-icon
              v-for="remaining in 5 - Math.ceil(review.rating / 2)"
              :key="remaining + Math.floor(review.rating / 2)"
              class="text-gray-300 align-middle h-6 -mx-0.5 inline-block align-middle"
            />
            <span class="font-semibold align-middle">
              {{ review.rating / 2 }}</span
            >
          </span>
        </div>
      </div>
      <div v-if="displayReport || displayRemove">
        <button
          @click="menuShow = !menuShow"
          class="self-start text-gray-400 p-2 -mr-4 -mt-2 rounded-full hover:bg-gray-50 active:bg-gray-100 transition"
        >
          <vertical-dots-icon class="h-6 inline-block" />
        </button>
        <div
          v-if="menuShow"
          @click="menuShow = !menuShow"
          class="z-30 fixed w-full h-full left-0 top-0 events-none"
        ></div>
        <div
          v-if="menuShow"
          class="absolute transform w-44 ml-6 mt-1 -translate-x-full z-40 border-2 border-gray-100 bg-white p-2 rounded-lg shadow-lg"
        >
          <ul class="space-y-2">
            <li
              v-if="displayReport"
              class="px-4 py-2 space-x-2 cursor-pointer hover:bg-gray-50 active:bg-gray-100 rounded-lg"
            >
              <flag-icon class="h-5 inline-block" />
              <span class="inline-block align-middle select-none"
                >Rapportera</span
              >
            </li>
            <li
              v-if="displayRemove"
              @click="remove"
              class="px-4 py-2 space-x-2 text-red-500 cursor-pointer hover:bg-red-50 active:bg-red-100 rounded-lg"
            >
              <trash-icon class="h-5 inline-block" />
              <span class="inline-block align-middle select-none">Ta bort</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p v-if="review.review">{{ review.review }}</p>
    <div v-if="review.review" class="space-x-4">
      <button
        class="inline-block align-middle cursor-pointer like-button"
        @click="like"
      >
        <span class="like-button-heart inline-block">
          <heart-icon
            v-if="review.liked"
            class="h-6 inline-block text-red-500"
          />
          <heart-icon-outline class="h-6 inline-block" v-else />
        </span>
        <span class="inline-block relative ml-2">
          <transition name="text-fade">
            <span
              class="font-semibold align-middle text select-none inline-block"
              :key="review.likes"
            >
              {{ review.likes }}</span
            >
          </transition>
        </span>
      </button>
    </div>
  </div>
</template>
<script>
var loading = false;
export default {
  props: ["review", "chipsId"],

  data: () => ({
    menuShow: false,
  }),

  methods: {
    async like() {
      if (loading) return;
      loading = true;
      if (this.review.liked) {
        try {
          await this.$root.unlike(this.review);
        } catch (err) {
          this.$root.alert("Kunde inte ta bort gilla-markering");
          console.error(err);
        }
      } else {
        try {
          await this.$root.like(this.review);
        } catch (err) {
          this.$root.alert("Kunde inte gilla");
          console.error(err);
        }
      }
      loading = false;
    },
    async remove() {
      this.menuShow = false;
      if (
        !(await this.$root.confirm(
          "Är du säker på att du vill ta bort omdömet?"
        ))
      )
        return;
      try {
        await this.$root.deleteReview(this.review, this.chipsId);
        this.review.rating = -1;
      } catch (err) {
        this.$root.alert("Omdömet kunde inte tas bort");
        console.error(err);
      }
    },
    formatDate(dateString) {
      let date = new Date(dateString);
      const months = [
        "jan",
        "feb",
        "mar",
        "apr",
        "maj",
        "jun",
        "jul",
        "aug",
        "sep",
        "okt",
        "nov",
        "dec",
      ];
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();

      let fullDate = `${day} ${months[month]} ${year}`;

      return fullDate;
    },
  },

  computed: {
    displayReport() {
      /*
      return (
        this.review.review &&
        this.$root.user &&
        ((this.$route.params.username &&
          this.$route.params.username != this.$root.user.username) ||
          (this.review.user && this.review.user.id != this.$root.user.id))
      );
      */
      return false;
    },
    displayRemove() {
      return (
        this.$root.user &&
        (this.$route.params.username == this.$root.user.username ||
          (this.review.user && this.review.user.id == this.$root.user.id))
      );
    },
  },
};
</script>
<style scoped>
.like-button-heart {
  transition: transform 0.35s cubic-bezier(0.25, 3, 0.57, 1);
}
.like-button:active .like-button-heart {
  transform: scale(0.85);
  transition: transform 0s;
}
</style>
