<template>
  <transition
    name="expand"
    @enter="updateHeight"
    @leave="updateHeight"
    @after-enter="resetHeight"
    @enter-cancelled="resetHeight"
    @after-leave="resetHeight"
    @leave-cancelled="resetHeight"
  >
    <div
      ref="slot"
      class="expand"
      :style="{
        height,
        'transition-duration': duration ? `${duration}s` : null,
      }"
      v-show="show"
    >
      <slot></slot>
    </div>
  </transition>
</template>
<script>
export default {
  data: () => ({
    height: undefined,
  }),
  methods: {
    updateHeight() {
      this.height = this.$refs["slot"].scrollHeight + "px";
    },
    resetHeight() {
      this.height = undefined;
    },
  },
  props: ["show", "duration"],
};
</script>
<style scoped>
.expand {
  transition: height 0.2s;
  overflow-y: hidden;
}
.expand-enter-active {
  height: 0px;
}
.expand-leave-to {
  height: 0px !important;
}
</style>
