<template>
  <div></div>
</template>
<script>
export default {
  props: ["callback"],
  data: () => ({
    loaded: false,
  }),
  methods: {
    checkScroll() {
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight
      ) {
        this.callback();
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.checkScroll);
    window.addEventListener("resize", this.checkScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkScroll);
    window.removeEventListener("resize", this.checkScroll);
  },
};
</script>