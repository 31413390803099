<template>
  <div class="container mx-auto mb-16 px-6">
    <page-heading title="Topp 10" />
    <p class="text-gray-400 text-center italic">Sveriges favoritchips</p>
    <div class="flex flex-col items-center mt-6 sm:space-y-4">
      <top-card
        v-for="(chip, index) in chips"
        class="sm:mx-4"
        :key="chip.id"
        :chip="chip"
        :count="index + 1"
      />
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import PageHeading from "@/components/PageHeading";
import TopCard from "@/components/TopCard";
export default {
  components: { PageHeading, TopCard },
  name: "Top",

  apollo: {
    chips: {
      query: gql`
        query TopChipsMessage($limit: Int, $offset: Int) {
          chips(limit: $limit, order_by: TOP, offset: $offset) {
            id
            brand {
              name
              id
            }
            slug
            name
            image
            subcategory
            rating
            reviews
          }
        }
      `,
      variables: {
        offset: 0,
        limit: 10,
      },
    },
  },
  metaInfo() {
    return {
      title: "Toppen",
    };
  },
};
</script>
