import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import "./index.pcss";
import "./registerServiceWorker";
import router from "./router";
import apolloProvider from "./vue-apollo";
import gql from "graphql-tag";
import InternalLink from "@/components/InternalLink";
Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.directive("focus", {
  inserted: function(el) {
    el.focus();
  },
});
Vue.component("CogIcon", require("@/icons/solid/cog.svg"));
Vue.component("UserCircleIcon", require("@/icons/solid/user-circle.svg"));
Vue.component("UserIcon", require("@/icons/solid/user.svg"));
Vue.component("LogoutIcon", require("@/icons/solid/logout.svg"));
Vue.component("SearchIcon", require("@/icons/outline/search.svg"));
Vue.component("LoginIcon", require("@/icons/solid/login.svg"));
Vue.component("HeartIcon", require("@/icons/solid/heart.svg"));
Vue.component("HeartIconOutline", require("@/icons/outline/heart.svg"));
Vue.component("StarIcon", require("@/icons/solid/star.svg"));
Vue.component("DotsIcon", require("@/icons/solid/dots-horizontal.svg"));
Vue.component("FlagIcon", require("@/icons/solid/flag.svg"));
Vue.component("XIcon", require("@/icons/solid/x.svg"));
Vue.component("ChevronRightIcon", require("@/icons/solid/chevron-right.svg"));
Vue.component("ChevronDownIcon", require("@/icons/solid/chevron-down.svg"));
Vue.component("ChevronLeftIcon", require("@/icons/outline/chevron-left.svg"));
Vue.component("CustomStarIcon", require("@/assets/star.svg"));
Vue.component("TrashIcon", require("@/icons/outline/trash.svg"));
Vue.component("VerticalDotsIcon", require("@/icons/outline/dots-vertical.svg"));
Vue.component("FlagIcon", require("@/icons/outline/flag.svg"));
Vue.component("HalfStarIcon", require("@/assets/half-star.svg"));
Vue.component("UserAddIcon", require("@/icons/solid/user-add.svg"));
Vue.component("UserRemoveIcon", require("@/icons/solid/user-remove.svg"));

Vue.component("InternalLink", InternalLink);

const cache = apolloProvider.defaultClient.cache;
export default new Vue({
  router,
  apolloProvider,
  data: () => ({
    animate: false,
    user: null,
    overlay: null,
    alerts: [],
    confirms: [],
  }),
  methods: {
    async confirm(message) {
      let resolve;
      let promise = new Promise(res => (resolve = res));
      this.confirms.push({ message, resolve });
      return promise;
    },
    alert(message, color = "red", time = 3) {
      let id =
        Date.now() +
        Math.random()
          .toString()
          .substring(2, 10);
      this.alerts.unshift({
        message,
        color,
        id,
      });
      if (time) {
        window.setTimeout(() => {
          let index = this.alerts.findIndex(x => x.id == id);
          if (index >= 0) this.alerts.splice(index, 1);
        }, time * 1000);
      }
    },
    back() {
      this.overlay = null;
      this.animate = true;
      this.$emit("push");
      try {
        this.$router.back();
      } catch (err) {
        this.animate = false;
      }
    },
    push(location) {
      this.overlay = null;
      this.animate = true;
      this.$emit("push");
      this.$router.push(location).catch(err => {
        this.animate = false;
        if (err.name != "NavigationDuplicated") {
          console.error(err);
        }
      });
    },
    hideOverlay(match) {
      if (match) this.overlay = null;
    },
    async createSnack(snack) {
      await this.$apollo.mutate({
        mutation: gql`
          mutation CreateChips($snack: NewChip!) {
            createChip(chip: $snack)
          }
        `,
        variables: {
          snack,
        },
      });
      this.$apollo.getClient().resetStore();
    },
    async follow(user) {
      var newFollowers;
      try {
        newFollowers =
          cache.readFragment({
            id: `User:${user.id}`,
            fragment: gql`
              fragment FollowUserCache on User {
                followers
              }
            `,
          }).followers + 1;
      } catch {}
      await this.$apollo.mutate({
        mutation: gql`
          mutation Follow($user: Int!) {
            follow(user: $user) {
              id
              follow
            }
          }
        `,
        variables: {
          user: user.id,
        },
        update: (store, { data }) => {
          store.writeFragment({
            id: `User:${user.id}`,
            fragment: gql`
              fragment FollowUser on User {
                follow
                followers @include(if: $includeFollowers)
              }
            `,
            data: {
              followers: newFollowers,
              follow: data.follow.follow,
              __typename: "User",
            },
            variables: {
              includeFollowers: newFollowers != null,
            },
          });
        },
        optimisticResponse: {
          __typename: "Mutation",
          follow: {
            __typename: "User",
            id: user.id,
            followers: newFollowers,
            follow: true,
          },
        },
      });
      try {
        var newFollowing =
          cache.readFragment({
            id: `User:${this.$root.user.id}`,
            fragment: gql`
              fragment FollowingOwnUserCache on User {
                following
              }
            `,
          }).following + 1;
        cache.writeFragment({
          id: `User:${this.$root.user.id}`,
          fragment: gql`
            fragment SetFollowingOwnUserCache on User {
              following
            }
          `,
          data: {
            following: newFollowing,
            __typename: "User",
          },
        });
      } catch {}
    },
    async unfollow(user) {
      var newFollowers;
      try {
        newFollowers =
          cache.readFragment({
            id: `User:${user.id}`,
            fragment: gql`
              fragment UnfollowUserCache on User {
                followers
              }
            `,
          }).followers - 1;
      } catch {}
      await this.$apollo.mutate({
        mutation: gql`
          mutation Unfollow($user: Int!) {
            unfollow(user: $user) {
              id
              follow
            }
          }
        `,
        variables: {
          user: user.id,
        },
        update: (store, { data }) => {
          store.writeFragment({
            id: `User:${user.id}`,
            fragment: gql`
              fragment UnfollowUser on User {
                follow
                followers @include(if: $includeFollowers)
              }
            `,
            data: {
              followers: newFollowers,
              follow: data.unfollow.follow,
              __typename: "User",
            },
            variables: {
              includeFollowers: newFollowers != null,
            },
          });
        },
        optimisticResponse: {
          __typename: "Mutation",
          unfollow: {
            __typename: "User",
            id: user.id,
            followers: newFollowers,
            follow: false,
          },
        },
      });
      try {
        var newFollowing =
          cache.readFragment({
            id: `User:${this.$root.user.id}`,
            fragment: gql`
              fragment FollowingOwnUserCache on User {
                following
              }
            `,
          }).following - 1;
        cache.writeFragment({
          id: `User:${this.$root.user.id}`,
          fragment: gql`
            fragment SetFollowingOwnUserCache on User {
              following
            }
          `,
          data: {
            following: newFollowing,
            __typename: "User",
          },
        });
      } catch {}
    },
    async like(review) {
      var newLikes = review.likes + 1;
      await this.$apollo.mutate({
        mutation: gql`
          mutation Like($review: Int!) {
            like(review: $review) {
              id
              liked
            }
          }
        `,
        variables: {
          review: review.id,
        },
        update: (store, { data }) => {
          store.writeFragment({
            id: `Review:${review.id}`,
            fragment: gql`
              fragment LikeReview on Review {
                likes
                liked
              }
            `,
            data: {
              likes: newLikes,
              liked: data.like.liked,
              __typename: "Review",
            },
          });
        },
        optimisticResponse: {
          __typename: "Mutation",
          like: {
            __typename: "Review",
            id: review.id,
            liked: true,
            likes: newLikes,
          },
        },
      });
    },
    async unlike(review) {
      var newLikes = review.likes - 1;
      await this.$apollo.mutate({
        mutation: gql`
          mutation Unlike($review: Int!) {
            unlike(review: $review) {
              id
              liked
            }
          }
        `,
        variables: {
          review: review.id,
        },
        update: (store, { data }) => {
          store.writeFragment({
            id: `Review:${review.id}`,
            fragment: gql`
              fragment UnlikeReview on Review {
                likes
                liked
              }
            `,
            data: {
              likes: newLikes,
              liked: data.unlike.liked,
              __typename: "Review",
            },
          });
        },
        optimisticResponse: {
          __typename: "Mutation",
          unlike: {
            __typename: "Review",
            id: review.id,
            liked: false,
            likes: newLikes,
          },
        },
      });
    },
    async deleteReview(review, chipsId) {
      await this.$apollo.mutate({
        mutation: gql`
          mutation deleteReview($review: Int!) {
            deleteReview(review: $review)
          }
        `,
        variables: {
          review: review.id,
        },
        update: store => {
          store.writeFragment({
            id: `Review:${review.id}`,
            fragment: gql`
              fragment DeleteReview on Review {
                rating
              }
            `,
            data: {
              rating: -1,
              __typename: "Review",
            },
          });
          try {
            let chip = store.readFragment({
              id: `Chip:${chipsId}`,
              fragment: gql`
                fragment UpdateRating on Chip {
                  rating
                  reviews
                }
              `,
            });
            store.writeFragment({
              id: `Chip:${chipsId}`,
              fragment: gql`
                fragment UpdateRating on Chip {
                  rating
                  reviews
                }
              `,
              data: {
                rating:
                  chip.reviews == 1
                    ? 0
                    : (chip.rating * chip.reviews - review.rating) /
                      (chip.reviews - 1),
                reviews: chip.reviews - 1,
                __typename: "Chip",
              },
            });
          } catch (err) {
            console.log("catch deleteReview update:", err);
          }
        },
      });
    },
    async createReview(review) {
      var data = await this.$apollo.mutate({
        mutation: gql`
          mutation createReview($review: NewReview!) {
            createReview(review: $review) {
              id
              rating
              review
              likes
              user {
                id
                username
                firstname
                lastname
                image
              }
              created
              edited
            }
          }
        `,
        variables: {
          review,
        },
        update: store => {
          try {
            let chip = store.readFragment({
              id: `Chip:${review.chips}`,
              fragment: gql`
                fragment UpdateRating on Chip {
                  rating
                  reviews
                }
              `,
            });
            store.writeFragment({
              id: `Chip:${review.chips}`,
              fragment: gql`
                fragment UpdateRating on Chip {
                  rating
                  reviews
                }
              `,
              data: {
                rating:
                  (chip.rating * chip.reviews + review.rating) /
                  (chip.reviews + 1),
                reviews: chip.reviews + 1,
                __typename: "Chip",
              },
            });
          } catch (err) {
            console.log("catch createReview update:", err);
          }
        },
      });
      return { ...data.data.createReview, user: this.user };
    },
    async login(email, password) {
      var data = await this.$apollo.mutate({
        mutation: gql`
          mutation Login($email: String!, $password: String!) {
            login(email: $email, password: $password) {
              token
              refresh
              expires
              user {
                id
                username
                firstname
                lastname
                image
              }
            }
          }
        `,
        variables: {
          email,
          password,
        },
        context: {
          noAuth: true,
        },
      });
      this.setSession(data.data.login);
    },
    async refresh() {
      var token = window.localStorage.getItem("refresh-token");
      if (!token) return;
      var data = await this.$apollo.mutate({
        mutation: gql`
          mutation Refresh($token: String!) {
            refresh(token: $token) {
              token
              expires
              user {
                id
                username
                firstname
                lastname
                image
              }
            }
          }
        `,
        variables: {
          token,
        },
        context: {
          noAuth: true,
        },
      });
      this.setSession(data.data.refresh);
    },
    async createUser(user, token, code) {
      var data = await this.$apollo.mutate({
        mutation: gql`
          mutation CreateUser($user: NewUser!) {
            createUser(user: $user) {
              token
              refresh
              expires
              user {
                username
                firstname
                lastname
                image
              }
            }
          }
        `,
        variables: {
          user: { ...user, token, code },
        },
        context: {
          noAuth: true,
        },
      });
      this.setSession(data.data.createUser);
    },
    async verifyEmail(email) {
      var data = await this.$apollo.mutate({
        mutation: gql`
          mutation VerifyEmail($email: String!) {
            validateEmail(email: $email)
          }
        `,
        variables: {
          email,
        },
        context: {
          noAuth: true,
        },
      });
      return data.data.validateEmail;
    },
    setSession(data) {
      console.log("setting session data");
      if (data.refresh) console.log("resetting store");
      this.user = data.user;
      window.localStorage.setItem("access-token", data.token);
      if (data.refresh)
        window.localStorage.setItem("refresh-token", data.refresh);
      window.localStorage.setItem(
        "access-token-expires",
        new Date(data.expires).getTime()
      );
      window.localStorage.setItem("user", JSON.stringify(this.user));
      if (data.refresh) this.$apollo.getClient().resetStore();
    },
    logout() {
      console.log("logout: deleting session data");
      console.log("resetting store");
      this.user = null;
      window.localStorage.removeItem("access-token-expires");
      window.localStorage.removeItem("access-token");
      window.localStorage.removeItem("refresh-token");
      window.localStorage.removeItem("user");
      this.$apollo.getClient().resetStore();
    },
  },
  created() {
    document.addEventListener("touchstart", () => {}, true);
    var user = window.localStorage.getItem("user");
    if (user) {
      console.log("loading user data");
      this.user = JSON.parse(user);
    }
  },
  watch: {
    $route() {
      this.overlay = null;
    },
    overlay(value) {
      if (value !== null && value != "account")
        document.body.classList.add("overflow-hidden");
      else document.body.classList.remove("overflow-hidden");
    },
    confirms(value) {
      if (value.length > 0) document.body.classList.add("overflow-hidden");
      else document.body.classList.remove("overflow-hidden");
    },
  },
  mounted() {
    document.addEventListener("keydown", e => {
      if (e.key == "Escape") {
        if (this.overlay == "search") this.overlay = null;
      }
    });
  },
  render: h => h(App),
}).$mount("#app");
