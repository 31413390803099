<template>
    <div class="container mx-auto flex flex-col items-center px-6 mb-8">
        <page-heading title="Admin" />
        <div class="pt-6 flex flex-col items-center">
            <internal-link to="/admin/add-snacks">
                <button class="group w-56 bg-white shadow-md font-semibold tracking-wide pl-2 py-3 rounded-lg">Lägg till snacks<chevron-right-icon class="h-6 inline-block group-hover:ml-2" /></button>
            </internal-link>
        </div>
    </div>
</template>
<script>
import PageHeading from '@/components/PageHeading.vue';

export default {
  components: { PageHeading }
}
</script>
