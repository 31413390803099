<template>
  <div
    class="overlay-bg px-4 pt-4 md:pt-28"
    @click="$root.overlay = null"
  >
    <div
      @click.stop
      class="w-128 m-auto rounded-xl max-h-full max-w-full flex flex-col"
    >
      <form
        class="flex items-center px-4 bg-white shadow-xl rounded-xl flex-shrink-0 space-x-2"
        @submit.prevent="submit"
      >
        <search-icon class="h-6 text-blue-400" />
        <input
          v-model="q"
          v-focus
          placeholder="Sök..."
          ref="search"
          @keydown.down.stop="scroll(true)"
          @keydown.up.stop="scroll(false)"
          class="flex-auto bg-transparent py-4 outline-none"
          autocomplete="off"
          autocapitalize="off"
        />
        <close-button />
      </form>
      <div class="px-4 overflow-y-auto">
        <internal-link
          :to="{
            name: 'User',
            params: {
              username: search.user.username,
            },
          }"
          class="block p-3 rounded-lg border-4 border-blue-400 bg-white my-2"
          v-if="search.user"
        >
          <user-circle-icon
            class="inline-block pr-2 align-middle text-gray-600 h-6"
          />{{ search.user.username }}
        </internal-link>
        <internal-link
          v-for="(chip, index) in search.chips"
          :key="chip.id"
          :ref="'search-chip-' + index"
          :to="{
            name: 'Chip',
            params: { chipSlug: chip.slug, brandSlug: chip.brand.id },
          }"
        >
          <div
            @mouseenter="active = index"
            class="flex items-center my-2 font-semibold tracking-wide p-3 rounded-lg bg-gray-100 border-4"
            :class="{ active: index === active }"
          >
            <picture class="h-10 min-w-6 mr-3 align-middle">
              <source
                :srcset="
                  `https://static.snackstoppen.se/snackstoppen/sm/snacks/${chip.image}.webp`
                "
                type="image/webp"
              />
              <img
                :src="
                  `https://static.snackstoppen.se/snackstoppen/png/snacks/${chip.image}.png`
                "
                onerror="this.onerror=null; this.parentElement.querySelectorAll('source').forEach(el=>el.remove()); this.src='/img/chips/placeholder.svg'"
                class="h-full mx-auto"
              />
            </picture>
            <div class="inline-block tracking-wider align-middle">
              {{ chip.name }}
              <p class="text-xs tracking-wider text-gray-400">
                {{ chip.brand.name }}
              </p>
            </div>
          </div>
        </internal-link>
      </div>
    </div>
  </div>
</template>

<script>
import CloseButton from "@/components/CloseButton.vue";
import gql from "graphql-tag";

export default {
  components: { CloseButton },
  data: () => ({ q: "", search: [], active: 0 }),
  methods: {
    submit() {
      var chip = this.search.chips[this.active];
      this.$root.push({
        name: "Chip",
        params: { chipSlug: chip.slug, brandSlug: chip.brand.id },
      });
      this.$root.overlay = null;
    },
    scroll(direction) {
      if (direction) {
        if (this.active < this.search.chips.length - 1) {
          this.active++;
          this.$refs["search-chip-" + this.active][0].$el.scrollIntoView({
            block: "nearest",
          });
        }
      } else {
        if (this.active > 0) {
          this.active--;
          this.$refs["search-chip-" + this.active][0].$el.scrollIntoView({
            block: "nearest",
          });
        }
      }
    },
  },
  apollo: {
    search: {
      query: gql`
        query SearchMessage($q: String!) {
          search(q: $q) {
            chips {
              id
              name
              image
              slug
              brand {
                id
                name
              }
            }
            user {
              username
              firstname
              lastname
              image
            }
          }
        }
      `,
      variables() {
        return {
          q: this.q,
        };
      },
    },
  },
  watch: {
    q() {
      this.active = 0;
    },
  },
};
</script>

<style scoped>
.active {
  @apply bg-white border-blue-400;
}
</style>
